<template>
  <main class="auth">
    <router-view/>
  </main>
</template>

<script>
export default {
  name: 'Auth'
}
</script>

<style scoped>

</style>
